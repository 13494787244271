import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./login";
import PageLayout from "../components/PageLayout";
import History from "./history";
import Bank from "./bank";
import Maker from "./maker";
import KasProBankRouter from "./kasprobank";
import ReleaserRouter from "./releaser/router";

export default function PageRouter() {
  const sid = localStorage.getItem("_sid");
  const isAuthenticated = sid ? true : false;

  return (
    <BrowserRouter>
      {!isAuthenticated ? (
        <Routes>
          <Route path="/" index element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route element={<PageLayout />}>
            <Route
              path="/kasprobank-account/*"
              element={<KasProBankRouter />}
            />
            <Route path="/bank-account" element={<Bank />} />
            <Route path="/maker" element={<Maker />} />
            <Route path="/releaser/*" index element={<ReleaserRouter />} />
            <Route path="/history-transaction" element={<History />} />
          </Route>
          <Route path="*" element={<Navigate to="/releaser" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}
