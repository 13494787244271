/* eslint-disable react-hooks/exhaustive-deps */
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import BankAccountService from "../../services/bankAccountService";

export default function KasProBank() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setIsModalType] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [form] = Form.useForm();
  const [search, setSearch] = useState("");
  const [kasproBankAccounts, setKasproBankAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [balance, setBalance] = useState();
  const [isCheckBalanceModalOpen, setIsCheckBalanceModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Partner ID",
      dataIndex: "partnerId",
    },
    {
      title: "Account Holder Name",
      dataIndex: "accountHolderName",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
    },
    {
      title: "Action",
      fixed: "right",
      render: (_, data) => (
        <Space direction="vertical">
          <Button
            block
            type="dashed"
            icon={<SearchOutlined />}
            onClick={() => onCheckBalance(data.accountNumber)}
          >
            Check Balance
          </Button>
          <Button
            block
            type="dashed"
            icon={<EditOutlined />}
            onClick={() => onEdit(data)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Confirmation"
            description="Are you sure to delete this item?"
            onConfirm={() => onDelete(data.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button block type="dashed" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchKasproBankAccount();
  }, [search, limit, offset]);

  const fetchKasproBankAccount = useCallback(async () => {
    try {
      setIsLoading(true);
      const params = {
        bankType: "KASPROBANK",
        limit,
        offset,
        ...(search ? { search } : {}),
      };
      const result = await BankAccountService.get(params);
      setKasproBankAccounts(result?.rows);
      setCount(result?.count);
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const openModal = useCallback((type) => {
    setIsModalOpen(true);
    setIsModalType(type);
    if (type === "CREATE") {
      form.resetFields();
    }
  });

  const onEdit = useCallback((data) => {
    setSelectedData(data);
    openModal("EDIT");
    form.setFieldsValue({
      name: data?.name ?? null,
      partnerId: data?.partnerId ?? null,
      email: data?.email ?? null,
      accountHolderName: data?.accountHolderName ?? null,
      accountNumber: data?.accountNumber ?? null,
    });
  });

  const onDelete = useCallback(async (id) => {
    try {
      setIsLoading(true);
      await BankAccountService.delete(id);
      message.success("Success");
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
      fetchKasproBankAccount();
    }
  });

  const modalOnFinish = useCallback((input) => {
    if (modalType === "CREATE") {
      createKasproBankAccount(input);
    } else if (modalType === "EDIT") {
      editKasproBankAccount(input);
    }
  });

  const createKasproBankAccount = useCallback(async (data) => {
    try {
      setIsLoading(true);
      await BankAccountService.create({
        ...data,
        type: "KASPROBANK",
        bankName: "KasproBank",
      });
      message.success("Success");
      setIsModalOpen(false);
      form.resetFields();
      fetchKasproBankAccount();
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const editKasproBankAccount = useCallback(async (data) => {
    try {
      setIsLoading(true);
      await BankAccountService.update(selectedData.id, {
        ...data,
        type: "KASPROBANK",
        bankName: "KasproBank",
      });
      message.success("Success");
      setIsModalOpen(false);
      form.resetFields();
      fetchKasproBankAccount();
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const onCheckBalance = useCallback(async (accountNumber) => {
    try {
      setIsLoading(true);
      const result = await BankAccountService.checkBalance(accountNumber);
      setBalance(result);
      setIsCheckBalanceModalOpen(true);
      setSelectedAccount(accountNumber);
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const onHandlePagination = useCallback((pageNumber) => {
    const currentPage = pageNumber - 1;
    const offset = currentPage * limit;
    setOffset(offset);
  });

  return (
    <>
      <Card>
        <Form>
          <Row gutter={16}>
            <Col span={24} md={12} lg={8}>
              <Form.Item name="search">
                <Input
                  allowClear
                  placeholder="Search here..."
                  prefix={<SearchOutlined />}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={12}
              lg={{ span: 8, offset: 8 }}
              style={{ textAlign: "right" }}
            >
              <Form.Item>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => openModal("CREATE")}
                  loading={isLoading}
                >
                  Add New
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={kasproBankAccounts}
          rowKey={(row) => row.id}
          loading={isLoading}
          scroll={{ x: 1000, y: "60vh" }}
          pagination={{
            responsive: true,
            showSizeChanger: true,
            total: count,
            onChange: onHandlePagination,
            onShowSizeChange: (_, pageSize) => {
              setLimit(pageSize);
            },
          }}
        />
      </Card>

      <Modal
        title="Kaspro Bank Account"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[]}
      >
        <Form layout="vertical" form={form} onFinish={modalOnFinish}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="email" label="Email">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="partnerId" label="Partner ID">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="accountHolderName"
                label="Account Holder Name"
                rules={[{ required: true }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="accountNumber"
                label="Account Number"
                rules={[{ required: true }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={isLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={isCheckBalanceModalOpen}
        onCancel={() => setIsCheckBalanceModalOpen(false)}
        footer={[]}
        width={800}
        bodyStyle={{
          background: "linear-gradient(197deg, #e400ff, #9a00fa)",
          padding: 30,
          margin: 20,
          color: "white",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3 style={{ fontWeight: "bold" }}>{balance?.customerName}</h3>
            <h4 style={{ fontWeight: "bold" }}>{selectedAccount}</h4>
          </div>
          <div>
            <img src="/logo_kasprobank.png" width={70} alt="" />
          </div>
        </div>
        <h3 style={{ paddingTop: "5px" }}>Balance</h3>
        <h1>{`${balance?.accountCurrency} ${parseFloat(
          balance?.accountBalance
        ).toLocaleString("id-ID")}`}</h1>
      </Modal>
    </>
  );
}
