/* eslint-disable react-hooks/exhaustive-deps */
import { ExportOutlined } from "@ant-design/icons";
import {
  Form,
  DatePicker,
  Card,
  Select,
  Row,
  Col,
  Table,
  Button,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useCallback, useEffect, useState } from "react";
import HistoryTransactionService from "../../services/historyTransactionService";
import BankAccountService from "../../services/bankAccountService";
import ExportExcel from "../../services/exportExcel";

dayjs.extend(customParseFormat);

const dateFormat = "DD-MM-YYYY";
const displayDateFormat = "DD MMM YYYY HH:mm:ss";

const { Text, Paragraph } = Typography;

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    render: (_, data) => dayjs(data.creationDate).format(displayDateFormat),
  },
  {
    title: "Sender",
    dataIndex: "sender",
    render: (_, data) => (
      <>
        <Paragraph>{data.debitAcc}</Paragraph>
        <Paragraph>{data.debitName}</Paragraph>
      </>
    ),
  },
  {
    title: "Destination",
    dataIndex: "destination",
    render: (_, data) => (
      <>
        <Paragraph>{data.creditAcc}</Paragraph>
        <Paragraph>{data.creditName}</Paragraph>
      </>
    ),
  },
  {
    title: "Detail",
    dataIndex: "detail",
    render: (_, data) => (
      <>
        <Paragraph>{data.sku}</Paragraph>
        <Paragraph>{data.paymentMethod}</Paragraph>
      </>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (_, data) => {
      const value = parseFloat(data.amount).toLocaleString("id-ID");
      return (
        <div>
          {data.currency} {value}
        </div>
      );
    },
  },
  {
    title: "Admin Fee",
    dataIndex: "adminFee",
    render: (_, data) => {
      const value = parseFloat(data.adminFee ?? 0).toLocaleString("id-ID");
      return (
        <div>
          {data.currency} {value}
        </div>
      );
    },
  },
  {
    title: "Inter Bank Fee",
    dataIndex: "interBankFee",
    render: (_, data) => {
      const value = parseFloat(data.interBankFee ?? 0).toLocaleString("id-ID");
      return (
        <div>
          {data.currency} {value}
        </div>
      );
    },
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    render: (_, data) => {
      const value = parseFloat(data.totalAmount).toLocaleString("id-ID");
      return (
        <div>
          {data.currency} {value}
        </div>
      );
    },
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "In/Out",
    dataIndex: "inOut",
  },
  {
    title: "Notes",
    dataIndex: "notes",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (_, data) => (
      <Text strong type={data.status === "Success" ? "success" : "danger"}>
        {data.status}
      </Text>
    ),
  },
  {
    title: "Remark",
    dataIndex: "remark",
    fixed: "right",
    render: (_, data) => (
      <Text strong type={data.remark === "Success" ? "success" : "danger"}>
        {data.remark}
      </Text>
    ),
  },
];

export default function History() {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [accountNumber, setAccountNumber] = useState("");
  const [histories, setHistories] = useState([]);
  const [kasproBankAccounts, setKasproBankAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchKasproBankAccount();
  }, []);

  useEffect(() => {
    fetchHistories();
  }, [startDate, endDate, accountNumber]);

  const fetchKasproBankAccount = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await BankAccountService.get({ bankType: "KASPROBANK" });
      const options = result.map((rs) => ({
        label: `${rs.accountNumber} - ${rs.accountHolderName}`,
        value: rs.accountNumber,
      }));
      setKasproBankAccounts(options);
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const fetchHistories = useCallback(async () => {
    try {
      setIsLoading(true);
      let params = {
        startDate: dayjs(startDate).format(dateFormat),
        endDate: dayjs(endDate).format(dateFormat),
      };
      if (accountNumber) {
        params = { ...params, accountNumber };
      }
      const result = await HistoryTransactionService.get(params);
      setHistories(result);
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const filterDate = useCallback((value) => {
    if (value) {
      setStartDate(value[0]);
      setEndDate(value[1]);
    }
  });

  const exportExcel = useCallback(async () => {
    try {
      if (histories.length > 0) {
        setIsLoading(true);
        const excelData = histories.map((data) => ({
          date: dayjs(data?.creationDate).format(displayDateFormat),
          tid: data?.tid,
          senderAccount: data?.debitAcc,
          senderName: data?.debitName,
          destinationAccount: data?.creditAcc,
          destinationName: data?.creditName,
          sku: data?.sku,
          paymentMethod: data?.paymentMethod,
          amount: data?.amount,
          adminFee: data?.adminFee,
          interBankFee: data?.interBankFee,
          totalAmount: data?.totalAmount,
          type: data?.type,
          inOut: data?.inOut,
          notes: data?.notes,
          status: data?.status,
          remark: data?.remark,
        }));
        await ExportExcel.export({
          excelData,
          fileName: "transaction_history",
        });
      } else {
        message.warning("Warning - No data to export");
      }
    } catch (error) {
      message.error("Error - Export to excel failed");
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Card>
      <Form>
        <Row gutter={16}>
          <Col span={24} md={12} lg={8}>
            <Form.Item name="accountNumber">
              <Select
                allowClear
                showSearch
                placeholder="Select Account Number"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={kasproBankAccounts}
                onChange={setAccountNumber}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item name="date" initialValue={[startDate, endDate]}>
              <DatePicker.RangePicker
                showTime={false}
                format={dateFormat}
                style={{ width: "100%" }}
                onChange={filterDate}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item>
              <Button
                type="primary"
                icon={<ExportOutlined />}
                loading={isLoading}
                onClick={exportExcel}
              >
                Export
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={histories}
        rowKey={(row) => row.id}
        loading={isLoading}
        scroll={{ x: 2000, y: "60vh" }}
        pagination={{ responsive: true, showSizeChanger: true }}
      />
    </Card>
  );
}
