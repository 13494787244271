import { ConfigProvider } from "antd";
import { GREEN } from "./constants/color";
import PageRouter from "./pages/router";

export default function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: GREEN,
        },
      }}
    >
      <PageRouter />
    </ConfigProvider>
  );
}
