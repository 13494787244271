import fetchInterceptor from "../config/FetchInterceptor";

export default class TransferService {
  static async create(data) {
    const url = "/v1/transfers";
    return await fetchInterceptor.post(url, data);
  }

  static async get(data) {
    const url = "/v1/transfers";
    return await fetchInterceptor.get(url, { params: data });
  }

  static async update(id, data) {
    const url = `/v1/transfers/${id}`;
    return await fetchInterceptor.patch(url, data);
  }

  static async delete(id) {
    const url = `/v1/transfers/${id}`;
    return await fetchInterceptor.delete(url);
  }

  static async releaseMaker(data) {
    const url = "/v1/transfer-release";
    return await fetchInterceptor.post(url, data);
  }
}
