/* eslint-disable react-hooks/exhaustive-deps */
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import BankAccountService from "../../services/bankAccountService";
import TransferService from "../../services/transferService";

const transferTypeOptions = [
  {
    value: "DISBURSEMENT",
    label: "Disbursement",
  },
  {
    value: "REPAYMENT",
    label: "Repayment",
  },
];

const channelOptions = [
  {
    value: "GROSIRONE",
    label: "GrosirOne",
  },
  {
    value: "PINJAM MODAL",
    label: "Pinjam Modal",
  },
  {
    value: "DOMPET KILAT",
    label: "Dompet Kilat",
  },
];

const transferMethod = [
  {
    value: "ONLINE",
    label: "ONLINE",
  },
  {
    value: "RTGS",
    label: "RTGS",
  },
  {
    value: "KLIRING",
    label: "KLIRING",
  },
];

export default function Maker() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectBox, setSelectBox] = useState("KASPROBANK");
  const [isAddressRequired, setIsAddressRequired] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [form] = Form.useForm();
  const [transferListForm] = Form.useForm();
  const [modalType, setModalType] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [kasproBankAccounts, setKasproBankAccounts] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [balance, setBalance] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);

  const columns = [
    {
      title: "Transfer",
      dataIndex: "transfer",
    },
    {
      title: "Source",
      dataIndex: "source",
      render: (_, data) => {
        const source = data.source.split("-");
        return (
          <div>
            <p>{source[1]}</p>
            <p>{source[2]}</p>
          </div>
        );
      },
    },
    {
      title: "Destination",
      dataIndex: "destination",
      render: (_, data) => {
        const destination = data.destination.split("-");
        return (
          <div>
            <p>{destination[1]}</p>
            <p>{destination[2]}</p>
          </div>
        );
      },
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      render: (_, data) => {
        return (
          <div>IDR {parseFloat(data.nominal).toLocaleString("id-ID")}</div>
        );
      },
    },
    {
      title: "Transfer Method",
      dataIndex: "transferMethod",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
    {
      title: "Action",
      fixed: "right",
      render: (_, data) => (
        <Space direction="vertical">
          <Button
            block
            type="dashed"
            icon={<EditOutlined />}
            onClick={() => onEdit(data)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Confirmation"
            description="Are you sure to delete this item?"
            onConfirm={() => onDelete(data)}
            okText="Yes"
            cancelText="No"
          >
            <Button block type="dashed" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchAccountList("KASPROBANK");
    fetchAccountList("BANK");
  }, []);

  const fetchAccountList = useCallback(async (type) => {
    try {
      setIsLoading(true);
      const result = await BankAccountService.get({ bankType: type });
      let options = [];
      for (const rs of result) {
        if (type === "KASPROBANK") {
          options.push({
            label: `${rs.accountHolderName} - ${rs.accountNumber}`,
            value: `${rs.id}-${rs.accountNumber}-${rs.accountHolderName}`,
          });
        } else if (type === "BANK") {
          options.push({
            label: `${rs.bankName} - ${rs.accountNumber} - ${rs.accountHolderName}`,
            value: `${rs.id}-${rs.accountNumber}-${rs.accountHolderName}`,
          });
        }
      }
      if (type === "KASPROBANK") {
        setKasproBankAccounts(options);
      } else {
        setBankAccounts(options);
      }
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const onCheckBalance = useCallback(async (value) => {
    try {
      setIsLoading(true);
      const accountNumber = value.split("-");
      const result = await BankAccountService.checkBalance(accountNumber[1]);
      setBalance(parseFloat(result.accountBalance).toLocaleString("id-ID"));
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const openModal = useCallback((type) => {
    setIsModalOpen(true);
    setModalType(type);
    if (type === "CREATE") {
      setIsAddressRequired(false);
      transferListForm.resetFields();
    }
  });

  const radioOnChange = useCallback((e) => {
    setSelectBox(e.target.value);
    transferListForm.resetFields(["destination", "address"]);
  });

  const modalOnFinish = useCallback((input) => {
    if (modalType === "CREATE") {
      setTransfers([
        ...transfers,
        {
          key: transfers.length + 1,
          transfer: input.transfer,
          source: input.source,
          destination: input.destination,
          nominal: parseFloat(input.nominal),
          transferMethod: input.transferMethod,
          address: input.address,
          notes: input.notes,
        },
      ]);
    } else if (modalType === "EDIT") {
      const index = transfers.findIndex((item) => item === selectedData);
      transfers[index].transfer = input.transfer;
      transfers[index].source = input.source;
      transfers[index].destination = input.destination;
      transfers[index].nominal = parseFloat(input.nominal);
      transfers[index].transferMethod = input.transferMethod;
      transfers[index].address = input.address;
      transfers[index].notes = input.notes;
    }
    setIsModalOpen(false);
    setSelectBox("KASPROBANK");
    setBalance(0);
    setIsDisabled(false);
    transferListForm.resetFields();
  });

  const onDelete = useCallback((data) => {
    let transfer = transfers.filter((item) => item !== data);
    setTransfers(transfer);
    transfers.length > 0 ? setIsDisabled(true) : setIsDisabled(false);
  });

  const onEdit = useCallback((data) => {
    setSelectedData(data);
    setSelectBox(data?.transfer);
    if (data?.transferMethod === "ONLINE") {
      setIsAddressRequired(false);
    } else {
      setIsAddressRequired(true);
    }
    openModal("EDIT");
    transferListForm.setFieldsValue({
      transfer: data?.transfer ?? null,
      source: data?.source ?? null,
      destination: data?.destination ?? null,
      nominal: data?.nominal ?? null,
      transferMethod: data?.transferMethod ?? null,
      address: data?.address ?? null,
      notes: data?.notes ?? null,
    });
    onCheckBalance(data?.source);
  });

  const onFinish = useCallback(async (input) => {
    try {
      setIsLoading(true);
      let data = {
        transferType: input.transferType,
        channel: input.channel,
        reference: input.reference,
      };
      if (transfers) {
        let transferItems = [];
        for (const transfer of transfers) {
          const source = transfer.source.split("-");
          const destination = transfer.destination.split("-");
          transferItems.push({
            sourceId: parseInt(source[0]),
            destinationId: parseInt(destination[0]),
            nominal: transfer.nominal,
            transferMethod: transfer.transferMethod,
            address: transfer.address ?? "",
            notes: transfer.notes ?? "",
          });
        }
        data = { ...data, transfers: transferItems };
      }
      await TransferService.create(data);
      message.success("Success");
      setTransfers([]);
      form.resetFields();
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const selectBankAccount = useCallback((value) => {
    if (transferListForm.getFieldValue("transferMethod") !== "ONLINE") {
      const bank = value.split("-");
      transferListForm.setFieldValue("address", bank[2]);
    }
  });

  const onTransferMethodChanged = useCallback((value) => {
    if (value !== "ONLINE") {
      setIsAddressRequired(true);
      const bank = transferListForm.getFieldValue("destination").split("-");
      transferListForm.setFieldValue("address", bank[2]);
    } else {
      setIsAddressRequired(false);
      transferListForm.resetFields(["address"]);
    }
  });

  return (
    <>
      <Card>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={24} md={12} lg={8}>
              <Form.Item
                name="transferType"
                label="Transfer Type"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Transfer Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={transferTypeOptions}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12} lg={8}>
              <Form.Item
                name="channel"
                label="Channel"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Channel"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={channelOptions}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12} lg={8}>
              <Form.Item
                name="reference"
                label="Reference"
                rules={[{ required: true }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col span={24}>
              <Button
                type="dashed"
                size="large"
                block
                icon={<PlusOutlined />}
                onClick={() => openModal("CREATE")}
                loading={isLoading}
              >
                Transfer List
              </Button>
            </Col>
          </Row>
          <Table
            dataSource={transfers}
            columns={columns}
            scroll={{ x: 1000, y: 400 }}
            pagination={false}
            style={{ marginBottom: 20 }}
            loading={isLoading}
            rowKey={(row) => row.key}
          />
          <Button
            type="primary"
            icon={<SaveOutlined />}
            htmlType="true"
            loading={isLoading}
            disabled={isDisabled}
          >
            Submit
          </Button>
        </Form>
      </Card>

      <Modal
        title="Transfer List"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[]}
      >
        <Form
          layout="vertical"
          form={transferListForm}
          onFinish={modalOnFinish}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="transfer"
                label="Transfer"
                initialValue={selectBox}
                rules={[{ required: true }]}
              >
                <Radio.Group onChange={radioOnChange} value={selectBox}>
                  <Radio value="KASPROBANK">Kaspro Bank to Kaspro Bank</Radio>
                  <Radio value="BANK">Kaspro Bank to Bank</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div style={{ textAlign: "right", fontWeight: "bold" }}>
                Balance: {isLoading ? <Spin size="small" /> : `IDR ${balance}`}
              </div>
              <Form.Item
                name="source"
                label="Source"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Source Kaspro Bank Account"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={kasproBankAccounts}
                  onChange={onCheckBalance}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="destination"
                label="Destination"
                rules={[{ required: true }]}
              >
                {selectBox === "KASPROBANK" && (
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Destination Kaspro Bank Account"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={kasproBankAccounts}
                  />
                )}
                {selectBox === "BANK" && (
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Destination Bank Account"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={bankAccounts}
                    onChange={selectBankAccount}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="nominal"
                label="Nominal"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="0"
                  formatter={(value) =>
                    value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="transferMethod"
                label="Transfer Method"
                initialValue="ONLINE"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Transfer Method"
                  optionFilterProp="children"
                  onChange={onTransferMethodChanged}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={transferMethod}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="address"
                label="Address (RTGS / KLIRING only)"
                rules={[{ required: isAddressRequired }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="notes" label="Notes">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={isLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
