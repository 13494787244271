/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button, Layout, Menu } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {
  BankOutlined,
  FormOutlined,
  LogoutOutlined,
  MonitorOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import {
  BACKGROUND_GRAY,
  CHARCOAL,
  GREEN,
  RED,
  WHITE,
} from "../constants/color";
import { useCallback, useEffect, useState } from "react";
import * as CryptoJS from "crypto-js";

export default function PageLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sessionName, setSessionName] = useState();
  const [sessionRole, setSessionRole] = useState();
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    getName();
    getRole();
    getMenu();
  }, [sessionName, sessionRole]);

  const getName = useCallback(() => {
    const hashedName = localStorage.getItem("_sn");
    if (hashedName) {
      const bytes = CryptoJS.AES.decrypt(
        hashedName,
        process.env.REACT_APP_CLIENT_SECRET
      );
      const name = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setSessionName(name);
    }
  });

  const getRole = useCallback(() => {
    const hashedRole = localStorage.getItem("_sr");
    if (hashedRole) {
      const bytes = CryptoJS.AES.decrypt(
        hashedRole,
        process.env.REACT_APP_CLIENT_SECRET
      );
      const role = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setSessionRole(role);
    }
  });

  const getMenu = useCallback(() => {
    if (sessionRole === "MAKER") {
      setMenuList([
        {
          key: "/kasprobank-account",
          label: "KASPROBANK ACCOUNT",
          title: "KASPROBANK ACCOUNT",
          icon: <BankOutlined />,
        },
        {
          key: "/bank-account",
          label: "BANK ACCOUNT",
          title: "BANK ACCOUNT",
          icon: <BankOutlined />,
        },
        {
          key: "/maker",
          label: "MAKER",
          title: "MAKER",
          icon: <FormOutlined />,
        },
        {
          key: "/releaser",
          label: "RELEASER",
          title: "RELEASER",
          icon: <ScheduleOutlined />,
        },
        {
          key: "/history-transaction",
          label: "HISTORY TRANSACTION",
          title: "HISTORY TRANSACTION",
          icon: <MonitorOutlined />,
        },
      ]);
    } else if (sessionRole === "APPROVAL") {
      setMenuList([
        {
          key: "/releaser",
          label: "RELEASER",
          title: "RELEASER",
          icon: <ScheduleOutlined />,
        },
        {
          key: "/history-transaction",
          label: "HISTORY TRANSACTION",
          title: "HISTORY TRANSACTION",
          icon: <MonitorOutlined />,
        },
      ]);
    } else {
      setMenuList([]);
    }
  });

  const openMenu = useCallback((e) => {
    navigate(e.key);
  });

  const signOut = useCallback(() => {
    try {
      setIsLoading(true);
      localStorage.removeItem("_sid");
      localStorage.removeItem("_sn");
      localStorage.removeItem("_sr");
      navigate("/", { replace: true });
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Layout>
      <Sider width={270} style={siderStyle} breakpoint="lg" collapsedWidth="0">
        <div style={siderWrapperStyle}>
          <div style={logoWrapperStyle}>
            <img src="logo_g1_white.png" width="110" alt="" />
          </div>
          <div style={menuWrapperStyle}>
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={[location.pathname]}
              items={menuList}
              onClick={openMenu}
              style={menuStyle}
            />
          </div>
          <div style={signOutButtonWrapperStyle}>
            <Button
              type="text"
              size="large"
              block
              icon={<LogoutOutlined />}
              style={signOutButtonStyle}
              onClick={signOut}
              loading={isLoading}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header style={contentHeaderStyle}>Hello, {sessionName}</Header>
        <Content style={contentStyle}>
          <Outlet context={[sessionRole]} />
        </Content>
      </Layout>
    </Layout>
  );
}

const logoWrapperStyle = {
  alignItems: "center",
  height: "7vh",
  justifyContent: "left",
  display: "flex",
  backgroundColor: GREEN,
  paddingLeft: "35px",
};

const siderWrapperStyle = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
};

const siderStyle = {
  left: 0,
  top: 0,
  bottom: 0,
  height: "100vh",
  backgroundColor: WHITE,
  zIndex: 3,
  position: "absolute",
};

const menuWrapperStyle = {
  display: "flex",
  flex: 1,
  padding: "10px",
  border: 0,
};

const menuStyle = {
  border: 0,
  fontWeight: 500,
};

const contentHeaderStyle = {
  background: GREEN,
  color: WHITE,
  height: "7vh",
  fontWeight: 500,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
};

const contentStyle = {
  color: CHARCOAL,
  backgroundColor: BACKGROUND_GRAY,
  height: "93vh",
  padding: "20px",
  overflowY: "auto",
  marginLeft: "270px",
};

const signOutButtonWrapperStyle = {
  padding: "15px",
  backgroundColor: WHITE,
};

const signOutButtonStyle = {
  backgroundColor: RED,
  color: WHITE,
};
