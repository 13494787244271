/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Input, message } from "antd";
import { BACKGROUND_GRAY } from "../../constants/color";
import { LoginOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import AuthService from "../../services/authService";
import * as CryptoJS from "crypto-js";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = useCallback(async (input) => {
    try {
      setIsLoading(true);
      const result = await AuthService.authenticate(input);
      const encryptedToken = CryptoJS.AES.encrypt(
        JSON.stringify(`${result.type} ${result.accessToken}`),
        process.env.REACT_APP_CLIENT_SECRET
      ).toString();
      const encryptedName = CryptoJS.AES.encrypt(
        JSON.stringify(result.data.name),
        process.env.REACT_APP_CLIENT_SECRET
      ).toString();
      const encryptedRole = CryptoJS.AES.encrypt(
        JSON.stringify(result.data.role),
        process.env.REACT_APP_CLIENT_SECRET
      ).toString();
      localStorage.setItem("_sid", encryptedToken);
      localStorage.setItem("_sn", encryptedName);
      localStorage.setItem("_sr", encryptedRole);
      navigate("/releaser", { replace: true });
      window.location.reload();
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <div style={wrapperStyle}>
      <Card
        title="Please sign in to your account"
        bordered={false}
        style={{ width: 400 }}
      >
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true }]}
          >
            <Input placeholder="Input your username" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder="Input your password" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              icon={<LoginOutlined />}
              loading={isLoading}
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export const wrapperStyle = {
  backgroundColor: BACKGROUND_GRAY,
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
};
