import { Navigate, Route, Routes } from "react-router";
import Releaser from ".";
import TransferReceipt from "../../pdf/transfer-receipt";

export default function ReleaserRouter() {
  return (
    <Routes>
      <Route path="/" index element={<Releaser />} />
      <Route path="/:id" element={<TransferReceipt />} />
      <Route path="*" element={<Navigate to="/releaser" replace />} />
    </Routes>
  );
}
