/* eslint-disable react-hooks/exhaustive-deps */
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import BankAccountService from "../../services/bankAccountService";
import BankService from "../../services/bankService";

export default function Bank() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setIsModalType] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [form] = Form.useForm();
  const [search, setSearch] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);
  const [banks, setBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "Account Holder Name",
      dataIndex: "accountHolderName",
      key: "accountHolderName",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, data) => (
        <Space direction="vertical">
          <Button
            block
            type="dashed"
            icon={<EditOutlined />}
            onClick={() => onEdit(data)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Confirmation"
            description="Are you sure to delete this item?"
            onConfirm={() => onDelete(data.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button block type="dashed" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchBankAccount();
  }, [search, limit, offset]);

  useEffect(() => {
    fetchBankList();
  }, []);

  const fetchBankAccount = useCallback(async () => {
    try {
      setIsLoading(true);
      const params = {
        bankType: "BANK",
        limit,
        offset,
        ...(search ? { search } : {}),
      };
      const result = await BankAccountService.get(params);
      setBankAccounts(result?.rows);
      setCount(result?.count);
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const fetchBankList = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await BankService.get();
      const options = result.map((item) => ({ label: item, value: item }));
      setBanks(options);
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const openModal = useCallback((type) => {
    setIsModalOpen(true);
    setIsModalType(type);
    if (type === "CREATE") {
      form.resetFields();
    }
  });

  const onEdit = useCallback((data) => {
    setSelectedData(data);
    openModal("EDIT");
    form.setFieldsValue({
      name: data?.name ?? null,
      email: data?.email ?? null,
      bankName: data?.bankName ?? null,
      accountHolderName: data?.accountHolderName ?? null,
      accountNumber: data?.accountNumber ?? null,
    });
  });

  const onDelete = useCallback(async (id) => {
    try {
      setIsLoading(true);
      await BankAccountService.delete(id);
      message.success("Success");
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
      fetchBankAccount();
    }
  });

  const modalOnFinish = useCallback((input) => {
    if (modalType === "CREATE") {
      createKasproBankAccount(input);
    } else if (modalType === "EDIT") {
      editKasproBankAccount(input);
    }
  });

  const createKasproBankAccount = useCallback(async (data) => {
    try {
      setIsLoading(true);
      await BankAccountService.create({
        ...data,
        type: "BANK",
        partnerId: "",
      });
      message.success("Success");
      setIsModalOpen(false);
      form.resetFields();
      fetchBankAccount();
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const editKasproBankAccount = useCallback(async (data) => {
    try {
      setIsLoading(true);
      await BankAccountService.update(selectedData.id, {
        ...data,
        type: "BANK",
        partnerId: "",
      });
      message.success("Success");
      setIsModalOpen(false);
      form.resetFields();
      fetchBankAccount();
    } catch (error) {
      message.error(`${error.error} - ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  const onHandlePagination = useCallback((pageNumber) => {
    const currentPage = pageNumber - 1;
    const offset = currentPage * limit;
    setOffset(offset);
  });

  return (
    <>
      <Card>
        <Form>
          <Row gutter={16}>
            <Col span={24} md={12} lg={8}>
              <Form.Item name="search">
                <Input
                  allowClear
                  placeholder="Search here..."
                  prefix={<SearchOutlined />}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={12}
              lg={{ span: 8, offset: 8 }}
              style={{ textAlign: "right" }}
            >
              <Form.Item>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => openModal("CREATE")}
                  loading={isLoading}
                >
                  Add New
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={bankAccounts}
          rowKey={(row) => row.id}
          loading={isLoading}
          scroll={{ x: 1000, y: "60vh" }}
          pagination={{
            responsive: true,
            showSizeChanger: true,
            total: count,
            onChange: onHandlePagination,
            onShowSizeChange: (_, pageSize) => {
              setLimit(pageSize);
            },
          }}
        />
      </Card>

      <Modal
        title="Bank Account"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[]}
      >
        <Form layout="vertical" form={form} onFinish={modalOnFinish}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="email" label="Email">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="bankName"
                label="Bank Name"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Bank"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={banks}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="accountHolderName"
                label="Account Holder Name"
                rules={[{ required: true }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="accountNumber"
                label="Account Number"
                rules={[{ required: true }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={isLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
