import { message } from "antd";
import axios from "axios";
import * as CryptoJS from "crypto-js";

const generateSignature = () => {
  const now = new Date().toLocaleDateString("id-ID", {
    timeZone: "Asia/Jakarta",
  });

  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  const base64Hash = CryptoJS.enc.Utf8.parse(`${clientId}:${clientSecret}`);
  const base64 = CryptoJS.enc.Base64.stringify(base64Hash);

  const signature = CryptoJS.HmacSHA512(
    `${base64},${now}`,
    clientSecret
  ).toString();

  return signature;
};

const fetchInterceptor = axios.create({
  baseURL: process.env.REACT_APP_MAKER_RELEASER_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

fetchInterceptor.interceptors.request.use(
  (config) => {
    const hashedToken = localStorage.getItem("_sid");
    config.headers["signature"] = generateSignature();
    if (hashedToken) {
      const bytes = CryptoJS.AES.decrypt(
        hashedToken,
        process.env.REACT_APP_CLIENT_SECRET
      );
      const bearerToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      config.headers["Authorization"] = bearerToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

fetchInterceptor.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.data.statusCode === 401) {
      localStorage.removeItem("_sid");
      localStorage.removeItem("_sn");
      localStorage.removeItem("_sr");
      message.error("Your session is expired, please re-login!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      if (error.code === "ECONNABORTED") {
        message.error(error.message);
      } else {
        return Promise.reject(error.response.data);
      }
    }
  }
);

export default fetchInterceptor;
