/* eslint-disable react-hooks/exhaustive-deps */
import {
  Page,
  Document,
  View,
  PDFViewer,
  Text,
  Image,
} from "@react-pdf/renderer";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import * as CryptoJS from "crypto-js";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const dateFormat = "DD MMM YYYY HH:mm:ss";

export default function TransferReceipt() {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    const encoded = CryptoJS.enc.Base64.parse(id);
    const decoded = CryptoJS.enc.Utf8.stringify(encoded);
    setData(JSON.parse(decoded));
  }, [id]);

  const currency = useCallback((value) => {
    return parseFloat(value).toLocaleString("id-ID");
  });

  return (
    <PDFViewer style={pdfViewer}>
      <Document title="Transfer Receipt">
        <Page size="A4" orientation="landscape">
          <View style={wrapper}>
            <View style={box}>
              <View style={header}>
                <Image src="/logo_g1.png" style={logo} />
                <Text style={headerTitle}>TRANSFER RECEIPT</Text>
              </View>

              <View style={content}>
                <View style={contentItem}>
                  <View style={item}>
                    <Text style={itemTitle}>Date:</Text>
                    <Text style={itemSubtitle}>
                      {dayjs(data?.updatedAt).format(dateFormat)}
                    </Text>
                  </View>
                  <View style={itemRight}>
                    <Text style={itemTitle}>Reference:</Text>
                    <Text style={itemSubtitle}>{data?.reference}</Text>
                  </View>
                </View>

                <View style={contentItem}>
                  <View style={item}>
                    <Text style={itemTitle}>Source:</Text>
                    <Text style={itemSubtitle}>
                      {data?.sourceBankAccount?.accountHolderName}
                    </Text>
                    <Text style={itemSubtitle}>
                      {data?.sourceBankAccount?.accountNumber}
                    </Text>
                  </View>
                  <View style={itemRight}>
                    <Text style={itemTitle}>Destination:</Text>
                    <Text style={itemSubtitle}>
                      {data?.destinationBankAccount?.accountHolderName}
                    </Text>
                    <Text style={itemSubtitle}>
                      {data?.destinationBankAccount?.accountNumber}
                    </Text>
                  </View>
                </View>

                <View style={contentItem}>
                  <View style={item}>
                    <Text style={itemTitle}>Nominal:</Text>
                  </View>
                  <View style={itemRight}>
                    <Text style={itemTitle}>
                      {`IDR ${currency(data?.nominal)}`}
                    </Text>
                  </View>
                </View>
              </View>
              <Text style={footer}>This receipt is generated by system</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

const logo = {
  width: 130,
};

const pdfViewer = {
  width: "100%",
  height: "100%",
};

const wrapper = {
  padding: 20,
  width: "100%",
  height: "auto",
};

const box = {
  border: "2px dashed #dddddd",
};

const header = {
  borderBottom: "2px solid #dddddd",
  padding: 20,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
};

const headerTitle = {
  fontSize: "18px",
};

const content = {
  padding: 20,
  width: "100%",
};

const contentItem = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: 10,
};

const item = {
  marginBottom: "10px",
};

const itemRight = {
  marginBottom: "10px",
  alignItems: "flex-end",
};

const itemTitle = {
  fontSize: "12px",
  marginBottom: 3,
};

const itemSubtitle = {
  fontSize: "12px",
  color: "gray",
};

const footer = {
  fontSize: "10px",
  textAlign: "center",
  color: "#dddddd",
  marginBottom: 5,
};
