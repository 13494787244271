import fetchInterceptor from "../config/FetchInterceptor";

export default class BankAccountService {
  static async get(data) {
    const url = "/v1/bank-accounts";
    return await fetchInterceptor.get(url, { params: data });
  }

  static async create(data) {
    const url = "/v1/bank-accounts";
    return await fetchInterceptor.post(url, data);
  }

  static async update(id, data) {
    const url = `/v1/bank-accounts/${id}`;
    return await fetchInterceptor.patch(url, data);
  }

  static async delete(id) {
    const url = `/v1/bank-accounts/${id}`;
    return await fetchInterceptor.delete(url, id);
  }

  static async checkBalance(accountNumber) {
    const url = `/v1/bank-accounts/check-balance/${accountNumber}`;
    return await fetchInterceptor.get(url);
  }
}
